@use "sass:map";
@import '../../App.scss';

.search-container{
  max-width: 800px;
  min-width: 270px;
  margin-bottom: 48px;
  display: block;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  filter: drop-shadow(-22px 4px 24px rgba(0, 0, 0, 0.12));
}

input#search-bar {
  margin: 0 auto;
  width: 100%;
  height: 53px;
  padding: 0 0 0 57px;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.14);
  clip-path: inset(-8px 0px -8px -8px);
  border-radius: 60px 0 0 60px;
  border: unset;
  outline: none;
  &:focus{
    &::-webkit-input-placeholder{
      transition: opacity 0.45s ease;
        opacity: 0;
    }
    &::-moz-placeholder {
      transition: opacity 0.45s ease;
        opacity: 0;
    }
    &:-ms-placeholder {
      transition: opacity 0.45s ease;
        opacity: 0;
    }
  }
}

input#search-little-bar, input#search-md-bar{
  border-radius: 48px;
  padding: 11px 18px 11px 14px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: white;

  &:focus-visible {
    outline: 1px solid rgba(255, 255, 255, 0.30);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
}

input#search-little-bar{
  width: 250px;
}

input#search-md-bar{
  width: 438px;
  height:40px;
  border: 1px solid rgba(146, 146, 157, 0.08);
  border-radius:12px;
  background: url('../../../assets/ui/search.svg') no-repeat 405px 8px rgba(146, 146, 157, 0.08);
  color:#92929D;
  &::placeholder {
    color: #92929D;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

}

.search-little-icon, .search-md-icon {
  position: relative;
  float: left;
  width: 15px;
  height: 15px;
  top: 15px;
  left: 33px;
}

.search-icon{
  position: relative;
  float: left;
  width: 22px;
  height: 22px;
  top: -37px;
  left: 19px;
}

.list-group-item {
  border: 0px;
  height: 45px;
  padding: 13px 10px !important;
}

.hr {
  width: 90%;
  background: #ebebeb;
  margin: auto;
}

.select-container {
  max-width: 800px;
  display: block;
  margin: 0 auto;
  margin-top: 48px;
  z-index: 1;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
}

.select-little-container {
  max-width: 800px;
  display: block;
  margin: 0 auto;
  margin-top: 48px;
  z-index: 1;
  position: relative;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
}

#search-select {
  margin: 0 auto;
  width: 100%;
  height: 53px;
  padding: 0 0 0 57px;
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.14);
  clip-path: inset(-8px -8px -8px 0px);
  border-radius: 0 60px 60px 0;
  border: unset;
  border-left: 1px solid rgb(205, 192, 192);
  outline: none;
}

#search-little-select {
  margin: 0 auto;
  width: 250px;
  height: 45px;
  padding: 11px 18px 11px 14px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0.14);
  border-radius: 48px;
  border-left: 1px solid rgb(205, 192, 192);
  outline: none;
}

.search-row {
  max-width: 800px;
}

.link-icon{
  position: relative;
  float: right;
  width: 6px;
  height: 9px;
  top: 6px;
}

#typology-dropdown {
  border: none;
  border-radius: 6px;
  min-width: 300px;
  padding: 0;
  left: 32px;
  top: 64px;
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.24));
}

#typology-little-dropdown {
  border: none;
  border-radius: 6px;
  min-width: 300px;
  padding: 0;
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.24));
}

.navbar-nav .dropdown-menu-sector {
  position: absolute;
  left: 35px;
  top: 56px;
}

.select-little-icon {
  position: relative;
  float: left;
  width: 15px;
  height: 15px;
  top: -31px;
  left: 33px;
}

.dropdown-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  border-radius: 6px;
}

.dropdown-item:hover {
  background-color: #f9f9f9;
}

.typologyDisabled input#search-bar {
  border-radius: 60px;
  clip-path: inset(-8px -8px -8px -8px);
}

@media (max-width: 768px) {

  input#search-bar {
    border-radius: 60px;
    clip-path: inset(-8px -8px -8px -8px);
  }

  input#search-md-bar {
    border-radius: 12px;
    width: 272px;
    clip-path: inset(-8px -8px -8px -8px);
  }

  #search-select {
    border-radius: 60px;
    clip-path: inset(-8px -8px -8px -8px);
  }

  .search-container{
    margin-bottom: 8px;
    padding: 0px;
  }

  .select-container {
    margin-top: 8px;
    padding: 0px;
  }

  #typology-dropdown {
    min-width: 300px;
  }
}
